import { useEffect, useLayoutEffect, useState } from "react";
import { View, Platform, ScrollView } from "react-native";
import { makeStyles } from "@rneui/themed";
import { EdgeInsets, useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";

import {
  useAcceptConsentsMutation,
  useAcceptConsentsWithCodeMutation,
  useGetValidateWithCodeQuery
} from "common/services/MemberConsentsService";
import { PushNotificationsHelper_isSupported } from "common/helpers/firebase/PushNotificationsHelper";
import { RootState, useAppDispatch } from "common/redux";
import { setAuthFlowFinished } from "common/redux/AuthSlice";

import ScreenContainer from "../../components/ui/ScreenContainer";
import { Biometrics_isSupported } from "../../helpers/BiometricsHelpers";

import Button from "../../components/ui/Button";
import Spacing from "../../components/ui/Spacing";
import ActivityIndicator from "../../components/ui/ActivityIndicator";
import CheckBox from "../../components/ui/CheckBox";
import Text from "../../components/ui/Text";
import StepIndicator from "../../components/ui/StepIndicator";
import { Linking_openURL } from "common/helpers/LinkingHelper";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import { AccessibilityHelper_getAccessibilityProps } from "../../helpers/AccessibilityHelper";

import StorageHelper from "common/helpers/StorageHelper";
import StorageEnum from "common/enums/StorageEnum";
import { isTruthy } from "common/helpers/helpers";
import useGetExpiringConsents from "../../hooks/useGetExpiringConsents";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import { Alert_show } from "common/helpers/AlertHelper";

const MissingLegalFormsScreen = ({
  navigation,
  route = undefined,
  authenticatedFlow = false
}) => {
  const insets = useSafeAreaInsets();
  const styles = useStyles(insets);
  const textStyles = useTextStyles();
  const dispatch = useAppDispatch();

  const code = route?.params?.code;

  const { isExpired, isExpiringSoon, isLoading, member } =
    useGetExpiringConsents();

  const { data: validationData } = useGetValidateWithCodeQuery(
    { code },
    { skip: code === undefined }
  );

  const effectiveDate =
    validationData?.effective_date ??
    DateTime.fromISO(member?.patient?.enrolled).toFormat("MM/dd/yyyy");

  const { notificationsEnabled } = useSelector(
    (state: RootState) => state.settings
  );
  const { hasCredentials, isLoggedIn } = useSelector(
    (state: RootState) => state.auth
  );

  const [
    acceptConsentsMutation,
    { isLoading: isPostLoading, isSuccess: isAcceptConsentsSuccess }
  ] = useAcceptConsentsMutation();

  const [
    acceptConsentsWithCodeMutation,
    { isLoading: isPostCodeLoading, isSuccess: isAcceptConsentsWithCodeSuccess }
  ] = useAcceptConsentsWithCodeMutation();

  const [privacyChecked, setPrivacyChecked] = useState<boolean>(false);
  const [telehealthChecked, setTelehealthChecked] = useState<boolean>(false);
  const [benefitsChecked, setBenefitsChecked] = useState<boolean>(false);
  const [smsChecked, setSMSChecked] = useState<boolean>(false);

  const resetToMainFlow = () => {
    if (navigation?.canGoBack()) navigation?.goBack();
    else
      StorageHelper.getItem(StorageEnum.AUTHENTICATION_USER_NAME).then(
        (userName) => {
          const initialRoute = isTruthy(userName)
            ? "Reauthentication"
            : "Landing";

          navigation.reset({
            index: 0,
            routes: [{ name: isLoggedIn ? "Dashboard" : initialRoute }]
          });
        }
      );
  };

  const goNext = () => {
    // This is required because we cannot call navigation object outside the navigation wrapper.
    // On Router.tsx we are using this and it is autoside navigation wrapper.
    // We need to check when consents are missing again so we prompt the user to accept them.
    if (authenticatedFlow || isLoggedIn) {
      return;
    }

    Biometrics_isSupported().then((supported) => {
      if (supported) {
        navigation.reset({
          index: 0,
          routes: [{ name: "Biometrics" }]
        });
      } else {
        PushNotificationsHelper_isSupported().then((isSupported) => {
          if (isSupported === false || (isSupported && notificationsEnabled)) {
            dispatch(setAuthFlowFinished());
          } else {
            navigation.reset({
              index: 0,
              routes: [{ name: "TurnOnNotifications" }]
            });
          }
        });
      }
    });
  };

  const handleSubmit = () => {
    if (member) {
      acceptConsentsMutation({
        member_id: member.patient.patient_id
      });
    } else {
      acceptConsentsWithCodeMutation({
        code
      });
    }
  };

  useEffect(() => {
    if (isLoading || isExpired || isExpiringSoon || code) return;

    if (isLoggedIn) {
      navigation.reset({
        index: 0,
        routes: [{ name: "Dashboard" }]
      });
    } else goNext();
  }, [isExpired, isLoading, isLoggedIn]);

  useEffect(() => {
    if (isAcceptConsentsSuccess || isAcceptConsentsWithCodeSuccess) {
      resetToMainFlow();
    }
  }, [isAcceptConsentsSuccess, isAcceptConsentsWithCodeSuccess, navigation]);

  useEffect(() => {
    if (code || hasCredentials) return;

    resetToMainFlow();
  }, [hasCredentials, code, navigation]);

  useEffect(() => {
    if (validationData?.is_success === false) {
      Alert_show({
        title: "Code is invalid",
        content: "Invalid code",
        dispatch,
        buttons: [
          {
            text: "Close",
            style: "destructive",
            onPress: () => {
              resetToMainFlow();
            }
          }
        ]
      });
    }
  }, [validationData]);

  useLayoutEffect(() => {
    if (isLoading || isExpired || isExpiringSoon || code) return;

    if (isLoggedIn) {
      navigation.reset({
        index: 0,
        routes: [{ name: "Dashboard" }]
      });
    } else goNext();
  });

  return (
    <ScreenContainer
      innerStyle={styles.container}
      limitWidthTabletScreen
      disableBottomInsets
    >
      <StepIndicator size={5} index={2} />

      <Spacing vertical={2} />

      <ScrollView>
        <View style={styles.formContainer}>
          {isLoading ? (
            <ActivityIndicator style={styles.activityIndicator} />
          ) : (
            <>
              <Text body style={styles.bodyText}>
                {LocalizedStrings.screens.missingLegalForms.header}
              </Text>

              <View style={styles.row} aria-label="TermsCheckbox">
                <CheckBox
                  testID="TermsCheckbox"
                  checked={privacyChecked}
                  onPress={() => setPrivacyChecked(!privacyChecked)}
                  {...AccessibilityHelper_getAccessibilityProps(
                    "TermsCheckbox"
                  )}
                />

                <Text body style={styles.bodyText}>
                  {LocalizedStrings.screens.missingLegalForms.iAgreeToThe}{" "}
                  <Text
                    link
                    onPress={() =>
                      Linking_openURL(
                        "https://copilotiq.com/terms-of-use/",
                        dispatch
                      )
                    }
                  >
                    {LocalizedStrings.screens.missingLegalForms.termsOfService}
                  </Text>
                  {", "}
                  <Text
                    link
                    onPress={() =>
                      Linking_openURL(
                        "https://copilotiq.com/privacy-policy/",
                        dispatch
                      )
                    }
                  >
                    {LocalizedStrings.screens.missingLegalForms.privacyPolicy}
                  </Text>
                  {", "}
                  <Text
                    link
                    onPress={() =>
                      Linking_openURL(
                        "https://copilotiq.com/notice-of-privacy-practices/",
                        dispatch
                      )
                    }
                  >
                    {
                      LocalizedStrings.screens.missingLegalForms
                        .noticeOfPrivacyPractices
                    }
                  </Text>
                </Text>
              </View>

              <View style={styles.row} aria-label="InformedCheckbox">
                <CheckBox
                  testID="InformedCheckbox"
                  checked={telehealthChecked}
                  onPress={() => setTelehealthChecked(!telehealthChecked)}
                  {...AccessibilityHelper_getAccessibilityProps(
                    "InformedCheckbox"
                  )}
                />

                <Text body style={styles.bodyText}>
                  {LocalizedStrings.screens.missingLegalForms.iAgreeTo}{" "}
                  <Text
                    link
                    onPress={() =>
                      Linking_openURL(
                        "https://copilotiq.com/telehealth-informed-consent/",
                        dispatch
                      )
                    }
                  >
                    {
                      LocalizedStrings.screens.missingLegalForms
                        .telehealthInformedConsent
                    }
                  </Text>{" "}
                  {LocalizedStrings.common.and}{" "}
                  <Text
                    link
                    onPress={() =>
                      Linking_openURL(
                        "https://copilotiq.com/rpm-informed-consent/",
                        dispatch
                      )
                    }
                  >
                    {
                      LocalizedStrings.screens.missingLegalForms
                        .remotePatientMonitoringInformedConsent
                    }
                  </Text>
                </Text>
              </View>

              <View style={styles.row} aria-label="AssignmentCheckbox">
                <CheckBox
                  testID="AssignmentCheckbox"
                  checked={benefitsChecked}
                  onPress={() => setBenefitsChecked(!benefitsChecked)}
                  {...AccessibilityHelper_getAccessibilityProps(
                    "AssignmentCheckbox"
                  )}
                />

                <Text body style={styles.bodyText}>
                  {LocalizedStrings.screens.missingLegalForms.iAgreeToThe}{" "}
                  <Text
                    link
                    onPress={() =>
                      Linking_openURL(
                        "https://copilotiq.com/assignment-of-benefits/",
                        dispatch
                      )
                    }
                  >
                    {
                      LocalizedStrings.screens.missingLegalForms
                        .assignmentOfBenefits
                    }
                  </Text>
                </Text>
              </View>

              <View style={styles.row} aria-label="SmsCheckbox">
                <CheckBox
                  testID="SmsCheckbox"
                  checked={smsChecked}
                  onPress={() => setSMSChecked(!smsChecked)}
                  {...AccessibilityHelper_getAccessibilityProps("SmsCheckbox")}
                />

                <Text body style={styles.bodyText}>
                  {LocalizedStrings.screens.missingLegalForms.iAgreeToReceive}{" "}
                  <Text
                    link
                    onPress={() =>
                      Linking_openURL(
                        "https://copilotiq.com/sms-consent/",
                        dispatch
                      )
                    }
                  >
                    {
                      LocalizedStrings.screens.missingLegalForms
                        .textMessagesFromCopilot
                    }
                  </Text>
                </Text>
              </View>
            </>
          )}
        </View>
      </ScrollView>

      {effectiveDate !== undefined && (
        <Text body style={textStyles.colorDarkGreyBlue}>
          These apply since my service enrollment with CopilotIQ on{" "}
          {effectiveDate}
        </Text>
      )}

      <Spacing vertical={2} />

      <Button
        disabled={
          privacyChecked === false ||
          benefitsChecked === false ||
          telehealthChecked === false ||
          smsChecked === false ||
          isLoading
        }
        title={LocalizedStrings.screens.missingLegalForms.agreeAndContinue}
        accessibilityLabel={"Agree and Continue"}
        loading={isPostLoading || isPostCodeLoading}
        containerStyle={!navigation.canGoBack() && styles.buttonContainer}
        onPress={handleSubmit}
      />

      {navigation.canGoBack() && (
        <>
          <Spacing vertical={2} />
          <Button
            title={LocalizedStrings.common.goBack}
            type="outline"
            accessibilityLabel={"Go Back"}
            containerStyle={
              isLoggedIn ? { marginBottom: 10 } : styles.buttonContainer
            }
            onPress={() => navigation.goBack()}
          />
        </>
      )}
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme, insets: EdgeInsets) => {
  const bottomInsets = insets.bottom + (Platform.OS === "android" ? 10 : 0);
  const marginBottom = Math.max(bottomInsets, 10);
  return {
    activityIndicator: {
      flex: 1
    },
    checkBoxContainer: {
      flexDirection: "row",
      alignItems: "center"
    },
    container: {
      flex: 1,
      marginHorizontal: 10
    },
    row: {
      flexDirection: "row",
      alignItems: "center"
    },
    bodyText: {
      color: theme.colors.darkGreyBlue,
      marginRight: 40
    },
    contentText: {
      color: theme.colors.greyBlue
    },
    underlineText: {
      textDecorationLine: "underline"
    },
    formContainer: {
      flex: 1,
      gap: 20
    },
    buttonContainer: {
      marginBottom
    }
  };
});

export default MissingLegalFormsScreen;
